export default {
	prefix: 'isd',
	iconName: 'magazin',
	niceName: 'magazin',
	icon: [
		58,
		58,
		[],
		'ff32',
		'M28.62.62c15.681.022 28.388 12.729 28.41 28.41 0 15.69-12.72 28.41-28.41 28.41S.21 44.72.21 29.03 12.93.62 28.62.62zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91s11.6 25.91 25.91 25.91c14.303-.017 25.893-11.607 25.91-25.91 0-14.31-11.6-25.91-25.91-25.91zm3.76 8.31a2.57 2.57 0 012.33.36 2.73 2.73 0 011.07 2.24v1.53a1.26 1.26 0 01-.5 1h2.87a2.72 2.72 0 012.72 2.73v23.86a2.73 2.73 0 01-2.72 2.73H17.62c-.69 0-1.25-.56-1.25-1.25V17.81a1.25 1.25 0 01.85-1.18zm5.77 7.6H18.87v24.35h19.28a.22.22 0 00.22-.23V19.26a.22.22 0 00-.22-.23zm-4.93-5.22l-8.07 2.75h8.63a1.26 1.26 0 01-.5-1v-1.52c0-.16-.06-.23-.06-.23z'
	]
};