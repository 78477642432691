export default {
	prefix: 'isd',
	iconName: 'faq',
	niceName: 'faq',
	icon: [
		58,
		58,
		[],
		'ff16',
		'M28.62.98c15.69 0 28.41 12.72 28.41 28.41-.022 15.681-12.729 28.388-28.41 28.41C12.93 57.8.21 45.08.21 29.39S12.93.98 28.62.98zm0 2.5c-14.31 0-25.91 11.6-25.91 25.91S14.31 55.3 28.62 55.3s25.91-11.6 25.91-25.91C54.513 15.087 42.923 3.497 28.62 3.48zm1.935 36.42a5.13 5.13 0 01-3.675 8.74 5.2 5.2 0 01-5.1-5.15 5.13 5.13 0 018.775-3.59zm-3.675.99a2.63 2.63 0 000 5.25 2.68 2.68 0 002.65-2.65 2.63 2.63 0 00-2.65-2.6zm-.26-30.75c7.74 0 12.75 4.53 12.75 11.54 0 5.39-3.24 9.61-8.71 11.42-.2.95-.42 1.93-.66 2.91a1.273 1.273 0 01-2.47-.62c.26-1 .49-2.07.7-3.07l.1-.47a1.24 1.24 0 01.88-.94c4.77-1.35 7.62-4.8 7.62-9.24 0-5.58-3.93-9-10.25-9a12.15 12.15 0 00-6.06 1.54 6.54 6.54 0 001.54 3.31 9.26 9.26 0 014-.84c3.52 0 5.88 2.28 5.88 5.68 0 2.86-1.85 5.9-7 6.26a34.44 34.44 0 001.29 6.77 1.25 1.25 0 01-1.21 1.56 1.24 1.24 0 01-1.21-.93 33.69 33.69 0 01-1.36-8.63c0-.69.56-1.25 1.25-1.25h.26c2 0 5.5-.5 5.5-3.8 0-2-1.23-3.18-3.38-3.18a6.89 6.89 0 00-3.64 1 1.24 1.24 0 01-1.4-.13 9 9 0 01-3.12-6.34 1.23 1.23 0 01.53-1.15 14.35 14.35 0 018.17-2.4z'
	]
};