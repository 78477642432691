export default {
	prefix: 'isd',
	iconName: 'jobboerse',
	niceName: 'jobboerse',
	icon: [
		57,
		57,
		[],
		'ff27',
		'M7.453 7.57c8.91-8.89 23.117-9.547 32.81-1.52 9.693 8.028 11.693 22.11 4.62 32.52l10.87 10.87a4.34 4.34 0 01-.21 6.12 4.541 4.541 0 01-3 1.34h-.18a4.001 4.001 0 01-2.88-1.18l-8.31-8.31a1.25 1.25 0 111.76-1.77l8.31 8.3a1.56 1.56 0 001.2.44 2 2 0 001.37-.61 1.86 1.86 0 00.17-2.57l-10.63-10.62c-.43.5-.89 1.01-1.37 1.5a24.27 24.27 0 01-17.27 7.15l-.416-.005A24.42 24.42 0 017.453 7.57zm21.494-4.243c-10.26-2.03-20.524 3.47-24.515 13.137-3.992 9.667-.598 20.807 8.105 26.606 8.702 5.8 20.29 4.644 27.676-2.76a21.77 21.77 0 006.4-15.5c-.01-10.459-7.407-19.454-17.666-21.483zm-.094 21.172c5.01 0 8 2.46 7.96 6.58v7.1a1.25 1.25 0 01-2.5 0v-7.07c0-3.07-2.56-3.96-4.93-4.07l-3.75 4.45a1.25 1.25 0 01-1.88 0l-4.07-4.48c-4.65.21-4.65 3.07-4.65 4.07v7.07a1.25 1.25 0 01-2.5 0v-7.07c0-4.18 2.8-6.58 7.69-6.58.353.002.69.15.93.41l3.49 3.84 3.21-3.81a1.26 1.26 0 011-.44zM21.359 8.326a6.57 6.57 0 019.924 5.903v2a6.62 6.62 0 01-6.57 6.65 6.61 6.61 0 01-6.56-6.65v-2a6.57 6.57 0 013.206-5.903zm5.412 2.221a4.07 4.07 0 00-6.158 3.692v2a4.07 4.07 0 108.13 0v-2a4.07 4.07 0 00-1.972-3.692z'
	]
};