export default {
	prefix: 'isd',
	iconName: 'login',
	niceName: 'login',
	icon: [
		53,
		54,
		[],
		'ff2f',
		'M47.07.819a5.8 5.8 0 015.8 5.8v40.9a5.8 5.8 0 01-5.8 5.8h-25a5.8 5.8 0 01-5.79-5.8v-11.36a1.25 1.25 0 012.5 0v11.36a3.3 3.3 0 003.29 3.3h25a3.3 3.3 0 003.3-3.3v-40.9a3.3 3.3 0 00-3.3-3.3h-25a3.3 3.3 0 00-3.29 3.3v11.36a1.25 1.25 0 01-2.5 0V6.619a5.8 5.8 0 015.79-5.8h25zm-24.11 16.25l9.09 9.09c.205.204.332.471.36.756a1.249 1.249 0 01-.551 1.197l-8.899 8.957a1.25 1.25 0 01-.89.34 1.21 1.21 0 01-.88-.37 1.24 1.24 0 010-1.76l6.959-6.96H1.62a1.25 1.25 0 010-2.5h26.531l-6.961-6.96a1.259 1.259 0 111.77-1.79z'
	]
};