export default {
	prefix: 'isd',
	iconName: 'reinigung',
	niceName: 'reinigung',
	icon: [
		51,
		63,
		[],
		'ff42',
		'M7.761 23.276a1.25 1.25 0 011.767 1.348c-.25 1.95-.85 4.7-2.2 10.51a45.72 45.72 0 00-.92 8v16.79h24.77v-13.4a5.65 5.65 0 00-.23-1.87H9.418a1.25 1.25 0 010-2.5h20.33a10.27 10.27 0 00-2.46-2.41c-.39-.25-10.76-6.93-10.76-13.84v-1.44a1.25 1.25 0 012.5 0v1.44c0 4.6 6.97 10.05 9.66 11.76 4.515 3.237 4.987 7.195 5.001 8.602v.16a4.952 4.952 0 01-.011.278v14.48c0 .69-.56 1.25-1.25 1.25H5.138c-.69 0-1.25-.56-1.25-1.25v-18a48 48 0 011-8.54c1.33-5.75 1.93-8.44 2.16-10.28a1.25 1.25 0 01.713-1.088zm18.757-6.802c.66-.207 1.363.16 1.57.82a12.29 12.29 0 004.2 6c.549.417.656 1.2.24 1.75a1.23 1.23 0 01-1 .5 1.27 1.27 0 01-.76-.26 15 15 0 01-5.07-7.24c-.207-.66.16-1.363.82-1.57zm9.64-12.78c.69 0 1.25.56 1.25 1.25v6.72c0 .69-.56 1.25-1.25 1.25h-7.53a10.05 10.05 0 00-8 6.17 1.24 1.24 0 01-1.14.74H3.618a1.24 1.24 0 01-1.16-.77l-1.83-4.49a1.25 1.25 0 01.26-1.35l8.84-9.14a1.27 1.27 0 01.9-.38zm-6.53 2.49h-18.46l-7.91 8.18 1.21 3h14.23a12.49 12.49 0 019.85-6.95l1.08-.01v-4.22zm19.14 5.97a1.94 1.94 0 110 3.88 1.94 1.94 0 010-3.88zm-6.14-2.97a2 2 0 011.94 1.95 1.95 1.95 0 11-1.94-1.95zm-7.72-2.99h-2.81v4.22h2.81v-4.22zm13.86.03a1.94 1.94 0 011.94 1.95 1.95 1.95 0 11-1.94-1.95zm-6.14-2.96a2 2 0 011.94 2 1.95 1.95 0 11-1.94-2zm6.14-2.95a1.94 1.94 0 110 3.88 1.94 1.94 0 010-3.88z'
	]
};