export default {
	prefix: 'isd',
	iconName: 'kooperationspartner',
	niceName: 'kooperationspartner',
	icon: [
		71,
		44,
		[],
		'ff31',
		'M12.49.108v3.82H24.7a1.25 1.25 0 010 2.5H12.49v20.8h3.8l7.06 12.1a3.37 3.37 0 003.87 1.7 1.26 1.26 0 011.51.93 1.25 1.25 0 01-1 1.48 7 7 0 01-1.4.15 5.74 5.74 0 01-5.14-3l-6.33-10.86h-2.37v4.21H1.62a1.25 1.25 0 010-2.5h8.37V2.608h-8.3a1.25 1.25 0 110-2.5h10.8zm15.11 33.91c.6-.345 1.365-.14 1.71.46l2.6 4.52.087.175c.28.514.73.919 1.273 1.145a1.59 1.59 0 001.06-.19l-.03-.03a1.25 1.25 0 011.41 2.06 4.05 4.05 0 01-2.19.66 3.79 3.79 0 01-.86-.1 4.66 4.66 0 01-2.92-2.47l-2.6-4.52c-.345-.6-.14-1.365.46-1.71zm6.427-4.954c.468.02.886.299 1.083.724l4 7.21.119.19c1.104 1.702 2.139 1.45 3.471.89l-.01.02a1.254 1.254 0 011 2.3 6.48 6.48 0 01-2.55.61c-1.35 0-2.92-.58-4.21-2.8v-.06l-4-7.15a1.25 1.25 0 011.097-1.934zM48.04 1.598l10.71 4.66v-3.45h10.8a1.25 1.25 0 010 2.5h-8.3v28.83h8.37v.02a1.25 1.25 0 010 2.5H58.71v-3.17h-4.29c-.72 1.89-2.41 5.45-5.1 5.69h-.3c-1.59 0-3.07-1.17-4.4-3.5l-5.58-8.81a1.25 1.25 0 012.11-1.34l5.65 8.86c.82 1.48 1.64 2.31 2.33 2.25.69-.06 1.62-1.16 2.41-2.75l-.39-.75a1.23 1.23 0 01-.46-.86l-9.55-18.2-4.3-.38-6.09 6.17a6.53 6.53 0 01-5.56 1.6c-2.97-.71-4.49-3.77-4.56-3.9l-.33-.7 8.66-11.27c3.09-3.91 6.34-4 6.48-4h12.6zm-.52 2.43H35.45c-.05 0-2.23.11-4.52 3l-7.66 10a4.59 4.59 0 002.51 1.94 4.14 4.14 0 003.38-1l6.72-6.77h.59c.05 0 4.65.47 5.54.47l.7.05 10.1 19.26 5.94-.06v-22l-11.23-4.89zm20.03 21.55a2 2 0 110 4 2 2 0 010-4zm-63.88-2.79a2 2 0 110 4 2 2 0 010-4z'
	]
};