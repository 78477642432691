export default {
	prefix: 'isd',
	iconName: 'mitarbeiterzufriedenheit',
	niceName: 'mitarbeiterzufriedenheit',
	icon: [
		73,
		61,
		[],
		'ff3a',
		'M36.996 15.107a6.93 6.93 0 016.92 6.92v19.92a6.92 6.92 0 01-6.92 6.92h-16.16l-9.86 11.44a1.25 1.25 0 01-.94.44 1.32 1.32 0 01-.44-.08 1.24 1.24 0 01-.86-1.21v-10.63h-1.52a6.92 6.92 0 01-6.92-6.92v-19.88a6.93 6.93 0 016.92-6.92zm0 2.5H7.216a4.43 4.43 0 00-4.42 4.42v19.88a4.42 4.42 0 004.42 4.42h2.8c.69 0 1.25.56 1.25 1.25v8.51l8-9.32a1.26 1.26 0 01.95-.44h16.78a4.42 4.42 0 004.42-4.42v-19.88a4.43 4.43 0 00-4.42-4.42zM65.976.667a6.93 6.93 0 016.97 6.88v19.89a6.92 6.92 0 01-6.92 6.91h-1.52v10.63c.003.522-.32.99-.81 1.17a1.36 1.36 0 01-.44.08 1.27 1.27 0 01-1-.44l-9.85-11.44h-5.2a1.25 1.25 0 010-2.5h5.75a1.26 1.26 0 011 .44l8 9.32v-8.51c0-.69.56-1.25 1.25-1.25h2.77a4.42 4.42 0 004.42-4.41V7.547a4.43 4.43 0 00-4.42-4.42h-29.78a4.43 4.43 0 00-4.42 4.42v4.45a1.25 1.25 0 01-2.5 0v-4.41a6.93 6.93 0 016.92-6.92zM31.99 33.793l.149.01a1.26 1.26 0 01.837 2.014c-2.28 2.95-6.45 4.78-10.89 4.78s-8.61-1.78-10.89-4.78a1.26 1.26 0 112-1.53c1.82 2.38 5.23 3.81 8.91 3.81 3.68 0 7.1-1.46 8.91-3.81h-.04a1.26 1.26 0 011.163-.483zm-4.214-9.756a2 2 0 110 4 2 2 0 010-4zm-11.34 0a2 2 0 110 4 2 2 0 010-4zm43.54-4.23a1.26 1.26 0 012 1.53c-2.28 2.95-6.45 4.79-10.89 4.79a16 16 0 01-4.21-.56 1.26 1.26 0 01-.88-1.54 1.24 1.24 0 011.54-.87 13.51 13.51 0 003.55.51c3.68 0 7.09-1.51 8.89-3.86zm-14.56-10.25a2 2 0 110 4 2 2 0 010-4zm11.34 0a2 2 0 110 4 2 2 0 010-4z'
	]
};