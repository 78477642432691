export default {
	prefix: 'isd',
	iconName: 'bewerbungsverfahren',
	niceName: 'bewerbungsverfahren',
	icon: [
		51,
		64,
		[],
		'ff09',
		'M18.561 16.72a8 8 0 017.92 7.96v10.71c0 4.47-1.77 7.26-4.9 7.84v18.65a1.25 1.25 0 01-2.5 0V42.36a.94.94 0 01-.06-.29 1 1 0 01.06-.3v-15.1a1.25 1.25 0 112.5 0v14c1.11-.38 2.4-1.57 2.4-5.28V24.68a5.47 5.47 0 00-5.46-5.46h-9.72a5.47 5.47 0 00-5.46 5.46v10.75c0 3.71 1.29 4.9 2.4 5.28v-14a1.25 1.25 0 112.5 0v15.11a.71.71 0 010 .57v19.49a1.25 1.25 0 01-2.5 0V43.24c-3.13-.59-4.9-3.38-4.9-7.85V24.72a8 8 0 018-8zm-4.86 24.43c.69 0 1.25.559 1.25 1.25v19.48a1.25 1.25 0 01-2.5 0V42.4c0-.691.56-1.25 1.25-1.25zm27.73 5.77a1.25 1.25 0 110 2.5h-5.47v9.88h10.67a1.25 1.25 0 110 2.5h-11.92c-.69 0-1.25-.56-1.25-1.25V48.17c0-.691.56-1.25 1.25-1.25zm6.69-.82a1.26 1.26 0 012 1.53l-7.18 9.28a1.29 1.29 0 01-.94.49h-.05a1.25 1.25 0 01-.93-.42l-3-3.31a1.25 1.25 0 011.86-1.67l2 2.2 6.26-8.1zm-6.69-21.6a1.25 1.25 0 110 2.5h-5.47v9.93h10.67a1.25 1.25 0 110 2.5h-11.92c-.69 0-1.25-.56-1.25-1.25V25.75c0-.691.56-1.25 1.25-1.25zm6.69-.77a1.26 1.26 0 112 1.53l-7.18 9.28a1.24 1.24 0 01-.94.48h-.05a1.27 1.27 0 01-.93-.41l-3-3.31a1.253 1.253 0 011.86-1.68l2 2.2 6.26-8.09zm-6.69-21.57a1.25 1.25 0 010 2.5h-5.47v9.89h10.67a1.25 1.25 0 110 2.5h-11.92c-.69 0-1.25-.56-1.25-1.25V3.41c0-.691.56-1.25 1.25-1.25zm-30.132.228a6.31 6.31 0 114.824 11.662A6.31 6.31 0 0111.3 2.388zM48.14 1.35a1.26 1.26 0 012 1.53l-7.18 9.28a1.24 1.24 0 01-.94.48h-.05a1.27 1.27 0 01-.93-.41l-3-3.31a1.25 1.25 0 111.86-1.67l1.98 2.2zm-34.44 3.07a3.8 3.8 0 00-3.8 3.8 3.81 3.81 0 103.8-3.8z'
	]
};