export default {
	prefix: 'isd',
	iconName: 'haustechnik-hausmeister-service',
	niceName: 'haustechnik-hausmeister-service',
	icon: [
		75,
		54,
		[],
		'ff1f',
		'M30.284 10.174a1.3 1.3 0 011.68 0l11 9.81a1.267 1.267 0 01-1.71 1.87l-10.13-9.08-18.88 16.92v21.2h12.43v-11.84a1.25 1.25 0 011.29-1.25h10.37c.69 0 1.25.56 1.25 1.25v11.84h12.43v-8.12a1.25 1.25 0 112.5 0v9.37c0 .69-.56 1.25-1.25 1.25h-14.89c-.69 0-1.25-.56-1.25-1.25v-11.84h-7.91v11.84c0 .69-.56 1.25-1.25 1.25h-15a1.25 1.25 0 01-1.22-1.29v-23c0-.354.149-.691.41-.93zm36.26-2.37c2.09-2.07 4.7-2.25 6.52-.43a4 4 0 011.24 3.41 5.44 5.44 0 01-1.67 3.08l-12 12a4.16 4.16 0 00-1 3.28v.32a9.23 9.23 0 01-2.67 6.8 9.12 9.12 0 01-6.4 2.6 9.23 9.23 0 01-3.44-.67 1.25 1.25 0 01-.41-2l4.13-4.14a1.75 1.75 0 00.45-1.18 1.84 1.84 0 00-1.82-1.83 1.65 1.65 0 00-1.27.52l-4.13 4.13a1.25 1.25 0 01-2-.42 9.12 9.12 0 018.79-12.52h.37a4.14 4.14 0 003.31-.95zm4.79 1.3c-1.12-1.1-2.35-.2-3 .44l-12 12a6.55 6.55 0 01-5.22 1.65h-.22a6.66 6.66 0 00-5 1.93 6.59 6.59 0 00-1.93 5.16l2.55-2.53a4.24 4.24 0 013-1.25l.245.007a4.32 4.32 0 014.065 4.323 4.23 4.23 0 01-1.26 3l-2.56 2.56a6.61 6.61 0 007.12-6.82v-.31a6.54 6.54 0 011.67-5.15l12-12 .161-.148c.415-.41.707-.93.84-1.502a1.5 1.5 0 00-.46-1.36zM31.044.694l18.3 16a1.25 1.25 0 01-1.64 1.88l-16.66-14.52-14.83 12.92v-9.08h-3.8l.05 12.38-9.48 8.3a1.25 1.25 0 11-1.65-1.88l8.63-7.56V5.394h8.75v6.08L31.044.694z'
	]
};