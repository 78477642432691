export default {
	prefix: 'isd',
	iconName: 'niederlassungen-kontakt',
	niceName: 'niederlassungen-kontakt',
	icon: [
		70,
		70,
		[],
		'ff3f',
		'M10.232 14.306a3.9 3.9 0 013.68 1.81l6.37 9.55a4.73 4.73 0 01.32 5.37l-2.78 5a91.78 91.78 0 007.43 8.2l.07.08a92.69 92.69 0 008.22 7.42l5-2.79a4.75 4.75 0 015.38.33l9.52 6.34a3.89 3.89 0 011.83 3.7c-.06.63-.53 1.56-4.12 6.28l-.3.4-.07.08-.76.84c-1.91 2.2-3.83 2.6-4.91 2.6a3.252 3.252 0 01-.8-.09c-8.12-1.16-17.09-6.63-26.67-16.27l-.6-.59-.44-.43c-9.52-9.29-15.36-18.88-16.52-26.88-.13-.59-.48-3.07 2.52-5.72l.006-.008.048-.048c.107-.103.364-.339.716-.654a1.24 1.24 0 011-.31c.361.05.685.25.89.55l7.27 10.88a1.25 1.25 0 11-2.05 1.42l-6.46-9.7c-1.73 1.7-1.46 3.02-1.46 3.05v.13c1.06 7.5 6.68 16.57 15.8 25.5.21.21.35.37.44.44.034.026.086.075.154.142l.251.252.215.216c9.23 9.26 17.73 14.55 25.3 15.61h.17c.06.01 1.35.24 3.06-1.53l-9.73-6.53a1.262 1.262 0 011.43-2.08l9.94 6.63a38.86 38.86 0 003.23-4.61v-.03c0-.13-.05-.652-.8-1.19l-9.54-6.35-.15-.11a2.26 2.26 0 00-2.58-.06l-5.72 3.19a1.24 1.24 0 01-1.38-.11 101.73 101.73 0 01-9.16-8.18 99.75 99.75 0 01-8.18-9.1 1.26 1.26 0 01-.09-1.3l3.19-5.78a2.26 2.26 0 00-.07-2.59 1.13 1.13 0 01-.1-.13l-6.39-9.57c-.477-.667-.962-.744-1.157-.75h-.253a7.59 7.59 0 00-2.52 1.54 1.256 1.256 0 01-1.52-2c2.2-1.68 3.21-2 3.83-2.06zM41.521.386l.39.003a27.76 27.76 0 0113.451 51.797 1.28 1.28 0 01-.63.16 1.24 1.24 0 01-1.08-.62 1.26 1.26 0 01.5-1.71 25.25 25.25 0 10-34.63-34.24 1.252 1.252 0 11-2.18-1.23 27.78 27.78 0 0124.2-14.16h-.021zm.051 10.15c5.8 0 10.5 4.701 10.5 10.5 0 5.32-8.42 19.7-9.42 21.32a1.27 1.27 0 01-1.13.59 1.27 1.27 0 01-1.03-.59c-.96-1.62-9.42-16.02-9.42-21.32 0-5.799 4.701-10.5 10.5-10.5zm-.05 2.47v.03a8 8 0 00-8 7.97c0 3.44 5.06 13.04 8 18.2 2.94-5.2 8-14.76 8-18.2a8 8 0 00-8-8zm.03 2.03a6 6 0 110 12 6 6 0 010-12zm-.023 2.48a3.53 3.53 0 10-.013 7.06 3.53 3.53 0 00.013-7.06z'
	]
};