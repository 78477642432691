export default {
	prefix: 'isd',
	iconName: 'aussenreinigung',
	niceName: 'aussenreinigung',
	icon: [
		73,
		55,
		[],
		'ff05',
		'M30.083 11.23a1.3 1.3 0 011.67 0l8 7a1.277 1.277 0 01-1.66 1.94l-7.16-6.29-18.9 16.93v21.2h12.43V40.17a1.25 1.25 0 011.29-1.25h10.37c.69 0 1.25.56 1.25 1.25v11.84h12.43v-8.12a1.25 1.25 0 112.5 0v9.37c0 .691-.56 1.25-1.25 1.25h-14.89c-.69 0-1.25-.559-1.25-1.25V41.42h-7.91v11.84c0 .691-.56 1.25-1.25 1.25h-15a1.25 1.25 0 01-1.21-1.35v-23c0-.353.149-.69.41-.93zM48.193.463a1.24 1.24 0 011.06.119c.67.4 16.36 10.06 20.79 18.11 3.38 6.16 2.71 10.83 1.6 13.7a15 15 0 01-8.42 8l1.9 5.5a1.26 1.26 0 01-1.19 1.66 1.26 1.26 0 01-1.18-.85l-1.9-5.53a16.38 16.38 0 01-3.9.48 15.34 15.34 0 01-7.9-2.13c-3.74-2.26-6.15-6.06-7-11.06-1.46-8.5 5.1-26.49 5.38-27.25a1.24 1.24 0 01.76-.749zm1.03 3.049c-1.53 4.5-5.87 17.97-4.75 24.5.73 4.24 2.74 7.47 5.81 9.34a13.24 13.24 0 009.71 1.41l-1.82-5.3-9.87-3.7a1.25 1.25 0 11.88-2.34l7.93 3-2.36-6.82-4.63-1.9a1.25 1.25 0 01-.7-1.62 1.27 1.27 0 011.63-.7l2.7 1.09-2.71-7.75a1.252 1.252 0 112.37-.81l2.69 7.8 1.39-2.17a1.252 1.252 0 012.11 1.35l-2.46 3.84 2.35 6.8 4.36-7.09a1.25 1.25 0 012.13 1.31l-5.44 8.84 1.88 5.44a12.48 12.48 0 006.91-6.61c1.34-3.34.83-7.33-1.52-11.55l-.194-.34c-3.625-6.137-14.596-13.566-18.396-16.02zm-18.4-1.68l10.65 9.15a1.26 1.26 0 01.13 1.77 1.25 1.25 0 01-1.76.13l-9-7.71-14.84 12.92V9.01h-3.8l.05 12.38-9.48 8.3a1.25 1.25 0 11-1.65-1.88l8.63-7.56V6.51h8.75v6.08l12.32-10.76z'
	]
};